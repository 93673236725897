import { computed, reactive } from '@nuxtjs/composition-api';

const state = reactive({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isQuoteModalOpen: false,
  quoteAdditionalData: null,
  isWishlistModalOpen: false,
  isCreateWishlistModalOpen: false,
  wishlistModalData: null,
  filterSidebarCategoryId: null,
  loginAndRegisterModalOpen: '',
});

const useUiState = () => {
  const isMobileMenuOpen = computed(() => state.isMobileMenuOpen);
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const isCartSidebarOpen = computed(() => state.isCartSidebarOpen);
  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const isWishlistSidebarOpen = computed(() => state.isWishlistSidebarOpen);
  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const loginAndRegisterModalOpen = computed(() => state.loginAndRegisterModalOpen);
  const toggleLoginAndRegisterModal = (modal = '') => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.loginAndRegisterModalOpen = state.loginAndRegisterModalOpen === '' && modal === '' ? 'login' : modal;
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const quoteAdditionalData = computed(() => state.quoteAdditionalData);
  const isQuoteModalOpen = computed(() => state.isQuoteModalOpen);
  const toggleQuoteModal = (additionalData = null) => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isQuoteModalOpen = !state.isQuoteModalOpen;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    state.quoteAdditionalData = additionalData;
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const wishlistModalData = computed(() => state.wishlistModalData);
  const isWishlistModalOpen = computed(() => state.isWishlistModalOpen);
  const toggleWishlistModal = (data = null) => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistModalOpen = !state.isWishlistModalOpen;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    state.wishlistModalData = data;
  };

  const isCreateWishlistModalOpen = computed(() => state.isCreateWishlistModalOpen);
  const toggleCreateWishlistModal = () => {
    state.isCreateWishlistModalOpen = !state.isCreateWishlistModalOpen;
  };

  const isNewsletterModalOpen = computed(() => state.isNewsletterModalOpen);
  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const isCategoryGridView = computed(() => state.isCategoryGridView);

  const isWishlistGridView = computed(() => state.isWishlistGridView);

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const filterSidebarCategoryId = computed(() => state.filterSidebarCategoryId);
  const isFilterSidebarOpen = computed(() => state.isFilterSidebarOpen);
  const toggleFilterSidebar = (categoryId: string = null) => {
    state.filterSidebarCategoryId = categoryId;
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  return {
    isCartSidebarOpen,
    isWishlistSidebarOpen,
    isNewsletterModalOpen,
    isCategoryGridView,
    isWishlistGridView,
    isFilterSidebarOpen,
    isMobileMenuOpen,
    isQuoteModalOpen,
    quoteAdditionalData,
    isWishlistModalOpen,
    wishlistModalData,
    isCreateWishlistModalOpen,
    loginAndRegisterModalOpen,
    toggleWishlistModal,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleMobileMenu,
    toggleQuoteModal,
    toggleCreateWishlistModal,
    toggleLoginAndRegisterModal,
    filterSidebarCategoryId,
  };
};

export default useUiState;
