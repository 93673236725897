/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-extraneous-dependencies
import { sharedRef, UseUserErrors, ComputedProperty } from '@vue-storefront/core';
import {
  useUser,
  // useUiNotification
} from '~/composables';
import { useUserOrder, useAddresses } from '@gemini-vsf/composables';
import { Customer } from '@gemini-vsf/api-client';
import { onMounted, useRoute, useRouter, Ref, useContext } from '@nuxtjs/composition-api';
import { CustomQuery } from '~/types/core';

export interface MenuItem {
  title: string;
  component: string;
  slug: string;
}

const MENU_ITEMS: MenuItem[] = [
  { title: 'Il mio profilo', component: 'BasicInfo', slug: 'il-mio-profilo' },
  { title: 'Import ordini', component: 'OrdersImport', slug: 'import-ordini' },
  { title: 'I miei indirizzi', component: 'AddressBook', slug: 'i-miei-indirizzi' },
  { title: 'I miei ordini', component: 'OrderHistory', slug: 'i-miei-ordini' },
  { title: 'Le mie rimanenze', component: 'NotShippedOrders', slug: 'le-mie-rimanenze' },
  { title: 'I miei documenti', component: 'MyDocuments', slug: 'i-miei-documenti' },
];

interface UseMyAccountInterface<USER, UPDATE_USER_PARAMS> {
  currentComponent: Ref<string>;
  MENU_ITEMS: MenuItem[];
  loadingUser: ComputedProperty<boolean>;
  user: ComputedProperty<USER>;
  changePage: (menu: MenuItem) => Promise<void>;
  updateUser: (params: UPDATE_USER_PARAMS) => Promise<void>;
  loadUser: (params?: { customQuery: CustomQuery }) => Promise<void>;
  userErrors: ComputedProperty<UseUserErrors>;
  changePassword: (params: UPDATE_USER_PARAMS) => Promise<void>;
  loadOrders: (params: any) => Promise<void>;
  orders: any;
  loadingOrders: ComputedProperty<boolean>;
  orderErrors: any;
  createAddress: (params: any) => Promise<void>;
  addressErrors: any;
  updateAddress: (params: any) => Promise<void>;
  logout: () => Promise<void>;
  getConsentValue: (consentType: string) => boolean | undefined;
  checkIfCustomerHasRequiredConsents: () => Promise<void>;
}

const itemInPathBySlug = (path: string) => MENU_ITEMS.find((m) => m.slug === path?.split('/').pop());
const itemInPathByComponent = (component: string) =>
  MENU_ITEMS.find((m) => {
    return m.component === component;
  });

const useMyAccount = (key: string): UseMyAccountInterface<Customer, any> => {
  const {
    app: { localePath },
  } = useContext();
  const {
    value: { path },
  } = useRoute();
  const router = useRouter();
  const currentComponent = sharedRef<string>(`${itemInPathBySlug(path)?.component || 'BasicInfo'}`, `current-component-${key}`);
  const { user, load: loadUser, loading: loadingUser, updateUser, error: userErrors, changePassword, logout } = useUser();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { orders, search: loadOrders, loading: loadingOrders, error: orderErrors } = useUserOrder();
  const { save: createAddress, error: addressErrors, update: updateAddress } = useAddresses();

  const changePage = async (menu: MenuItem) => {
    currentComponent.value = menu.component;
    const newSlug = menu.component === 'BasicInfo' ? '' : menu.slug;
    await router.push(localePath(`/my-account/${newSlug}`));
  };

  const getConsentValue = (consentType: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const consentItem = user.value.consents.find((consent) => consent.type === consentType.toUpperCase());
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return consentItem ? (consentItem.value as boolean) : undefined;
  };

  const checkIfCustomerHasRequiredConsents = async () => {
    if (user.value && (getConsentValue('terms_and_conditions') === undefined || getConsentValue('privacy') === undefined)) {
      await updateUser({
        user: {
          consents: [
            {
              consent_type: 'PRIVACY',
              consent_value: true,
            },
            {
              consent_type: 'TERMS_AND_CONDITIONS',
              consent_value: true,
            },
            {
              consent_type: 'MARKETING',
              consent_value: getConsentValue('marketing') ?? false,
            },
          ],
        },
      });
      await loadUser();
    }
  };

  onMounted(async () => {
    if (!user.value && !loadingUser.value) {
      await loadUser();
    }
    if (path.split('/').filter(Boolean).length === 2) {
      // console.log('path', path);
      // await router.push(`${path}/${MENU_ITEMS[0].slug}`);
    }
  });

  return {
    currentComponent,
    MENU_ITEMS,
    loadingUser,
    user,
    changePage,
    updateUser,
    loadUser,
    userErrors,
    changePassword,
    loadOrders,
    orders,
    loadingOrders,
    orderErrors,
    createAddress,
    addressErrors,
    updateAddress,
    logout,
    getConsentValue,
    checkIfCustomerHasRequiredConsents,
  };
};
export default useMyAccount;
