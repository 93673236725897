/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useContext, useRoute } from '@nuxtjs/composition-api';
import { Cart, CartItem, userGetters } from '@gemini-vsf/composables';
import { Config, Customer } from '@gemini-vsf/api-client';
import { AdditionalData, ColfertCartItem, ColfertOrder, ColfertProduct, GtmCategories } from '~/types/types';

// interface UseGtmInterface {
//   getCategoryForGtm: (product: ColfertProduct) => string;
// }

interface LocalConfig extends Config {
  geminiBaseUrl: string;
}

const useGtm = () => {
  const {
    app: {
      $gtm,
      $vsf: {
        $gemini: { config },
      },
    },
  } = useContext();
  const { geminiBaseUrl } = config as LocalConfig;
  const route = useRoute();
  const { fullPath } = route.value;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gtmPush = (data: any) => {
    // @ts-ignore
    if (typeof window === 'undefined' || !window.Cookiebot) return;
    try {
      // @ts-ignore
      const { Cookiebot: { consent = {} } } = window;
      const hasAllConsents = (consent && (consent.marketing && consent.preferences && consent.statistics)) || false
      if (hasAllConsents) $gtm.push(data)
    } catch (error) {
      console.error('error while parsing consents data', error)
    }
  }

  const getCategoryForGtm = (product: ColfertProduct, returnType = 'url'): string => {
    const sortedCategoriesForGtm: GtmCategories = product?.categories
      ?.map((c) => ({
        urlArray: c.url_path?.split('/'),
        name: c.name,
        url: c.url_path,
        uid: c.uid,
      }))
      .reduce((a, b) => (a?.url?.length > b?.url?.length ? a : b));
    let returnValue = '';
    switch (returnType) {
      case 'uid': {
        returnValue = sortedCategoriesForGtm?.uid || '';
        break;
      }
      case 'name': {
        returnValue = sortedCategoriesForGtm?.name || '';
        break;
      }
      default: {
        returnValue =
          sortedCategoriesForGtm?.urlArray?.length > 1
            ? sortedCategoriesForGtm.urlArray[1]
            : sortedCategoriesForGtm?.urlArray?.length > 0
              ? sortedCategoriesForGtm.urlArray[0]
              : '';
      }
    }
    return returnValue;
  };

  const getProductDataFromCartForGtm = (product: ColfertCartItem) =>
    product.product.sku.includes('/')
      ? {
        name: product?.product?.name || '',
        id: product?.product?.uid || '',
        sku: product?.product?.merchant_sku /* .split('_') */ || '', // ToDo:: differentiate parent sku from variant sku
        price: product?.product?.configurable_product_options_selection?.variant?.price_range?.minimum_price?.final_price?.value || '',
        manufacturer: product?.product?.marchio_attribute?.label || '',
        category: getCategoryForGtm(product.product),
        variant: product?.product?.merchant_sku || '',
        quantity: product.quantity,
      }
      : {
        name: product?.product?.name || '',
        id: product?.product?.uid || '',
        sku: product?.product?.merchant_sku || '',
        price: product?.product?.price_range?.minimum_price?.final_price.value || '',
        manufacturer: product?.product?.marchio_attribute?.label || '',
        category: getCategoryForGtm(product.product),
        quantity: product.quantity,
      };

  const addToCartPush = (product: ColfertProduct, additionalData?: AdditionalData) => {
    const currency = additionalData?.currencyCode || product?.price_range?.minimum_price?.final_price.currency || 'EUR';
    const productData =
      product?.vsf_typename === 'ConfigurableProduct'
        ? {
          name: product?.name || '',
          id: product?.uid || '',
          sku: product?.merchant_sku || '',
          price: product?.configurable_product_options_selection?.variant?.price_range?.minimum_price?.final_price?.value || '',
          manufacturer: product?.marchio_attribute?.label || '',
          category: getCategoryForGtm(product),
          variant: product?.configurable_product_options_selection?.variant?.merchant_sku || '',
          quantity: 1,
        }
        : {
          name: product?.name || '',
          id: product?.uid || '',
          sku: product?.merchant_sku || '',
          price: product?.price_range?.minimum_price?.final_price.value || '',
          manufacturer: product?.marchio_attribute?.label || '',
          category: getCategoryForGtm(product),
          quantity: 1,
        };
    const filteredAdditionalData = { ...additionalData };
    if (filteredAdditionalData.currencyCode) delete filteredAdditionalData.currencyCode;
    gtmPush({
      event: 'add_to_cart',
      ecommerce: {
        currencyCode: currency,
        click: {
          products: [{ ...productData, ...additionalData }],
        },
      },
    });
  };

  const removeFromCartPush = (product: ColfertCartItem) => {
    gtmPush({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: [getProductDataFromCartForGtm(product)],
        },
      },
    });
  };

  // const checkoutPush = (step: string, option: string, products: ColfertCartItem[]) => {
  //   const productsData = [];
  //   products?.forEach((p) => {
  //     productsData.push(getProductDataFromCartForGtm(p));
  //   });
  //   productsData.filter(Boolean);
  //   gtmPush({
  //     event: 'checkout',
  //     ecommerce: {
  //       checkout: {
  //         actionField: { step, option },
  //         products: productsData,
  //       },
  //     },
  //   });
  // };

  const purchasePush = (
    order: ColfertOrder,
    userData: {
      firstname: string;
      lastname: string;
      email: string;
      is_subscribed: false;
    }
  ) => {
    const productsData = order?.items?.map((p) => {
      return p.product.vsf_typename === 'ConfigurableProduct'
        ? {
          name: p?.product?.name || '',
          uid: p?.product?.uid || '',
          sku: p?.product?.sku /* .split('_') */ || '', // ToDo:: differentiate parent sku from variant sku
          item_sale_price: p?.product?.configurable_product_options_selection?.variant?.price_range?.minimum_price?.final_price?.value || '',
          item_brand: p?.product?.marchio_attribute?.label || '',
          categories: p?.product?.categories?.reduce((a, b) => (a.name.length > b.name.length ? a : b))?.name || '',
          variant: p?.product?.sku || '',
          qty: p?.quantity || 1,
          type: p?.product?.vsf_typename,
        }
        : {
          name: p?.product?.name || '',
          uid: p?.product?.uid || '',
          sku: p?.product?.sku /* .split('_') */ || '', // ToDo:: differentiate parent sku from variant sku
          item_sale_price: p?.product?.price_range?.minimum_price?.final_price.value || '',
          item_brand: p?.product?.marchio_attribute?.label || '',
          categories: p?.product?.categories?.reduce((a, b) => (a.name.length > b.name.length ? a : b))?.name || '',
          qty: p?.quantity || 1,
          type: p?.product?.vsf_typename,
        };
    });
    gtmPush({
      event: 'purchase',
      ecommerce: {
        created_at: order.created_at,
        order_number: order.order_number,
        total: order.total_item,
        items: productsData,
        ...userData,
        PageType: 'thank-you',
      },
    });
  };

  const loginPush = (user: Customer) => {
    if (user) {
      gtmPush({
        customerLoggedIn: 1,
        firstname: userGetters.getFirstName(user),
        lastname: userGetters.getLastName(user),
        email: userGetters.getEmailAddress(user),
      });
    }
  };

  const logoutPush = (user: Customer) => {
    if (user) {
      gtmPush({
        customerLoggedIn: 0,
        firstname: userGetters.getFirstName(user),
        lastname: userGetters.getLastName(user),
        email: userGetters.getEmailAddress(user),
      });
    }
  };

  const checkoutStepPush = (step: number, params: { option?: string; description: string }) => {
    gtmPush({
      event: 'checkout',
      ecommerce: {
        currencyCode: 'EUR',
        checkout: {
          actionField: {
            step,
            description: params?.description,
            action: 'checkout',
          },
        },
      },
    });
    if (params?.option) {
      gtmPush({
        event: 'checkoutOption',
        ecommerce: {
          checkout_option: {
            actionField: {
              step,
              option: params?.option,
              action: 'checkout_option',
            },
          },
        },
      });
    }
  };

  const cartPush = (cart: Cart, cartItems: CartItem[]) => {
    if (cart) {
      gtmPush({
        transactionEntity: 'QUOTE',
        transactionId: cart?.id || '',
        transactionTotal: cart?.prices?.grand_total?.value || 0,
        transactionTax: cart?.prices?.applied_taxes?.[0]?.amount?.value || 0,
        transactionAffiliation: geminiBaseUrl,
        PageType: 'cart-page',
        transactionProducts: {
          cartItems,
        },
        ecommerce: {
          currencyCode: cart?.prices?.grand_total?.currency || 'EUR',
        },
        event: 'view_cart',
      });
    }
  };

  const categoryPush = (products: ColfertProduct[], catData: { name: string; uid: string }) => {
    if (products?.length === 0) return;
    const productList = products.map((p, idx) => {
      return p?.vsf_typename === 'ConfigurableProduct'
        ? {
          item_id: p?.uid || '',
          item_name: p?.name,
          sku: p?.merchant_sku || '',
          price: p?.configurable_product_options_selection?.variant?.price_range?.minimum_price?.final_price?.value || '',
          category: getCategoryForGtm(p),
          list_sku_variants: p?.list_sku_variants || [],
          item_brand: p?.marchio_attribute?.label || '',
          position: idx,
        }
        : {
          item_id: p?.uid || '',
          item_name: p?.name,
          sku: p?.merchant_sku || '',
          price: p?.price_range?.minimum_price?.final_price.value || '',
          category: getCategoryForGtm(p),
          item_brand: p?.marchio_attribute?.label || '',
          position: idx,
        };
    });
    gtmPush({
      ecommerce: {
        impression: {
          productList,
        },
      },
      categoryProducts: {
        productList,
      },
      categorySize: productList?.length || 0,
      categoryId: catData?.uid || '',
      categoryName: catData?.name || '',
      PageType: 'product-list',
      event: 'view_item_list',
    });
  };

  const productPush = (product: ColfertProduct) => {
    const gtmProduct =
      product?.vsf_typename === 'ConfigurableProduct'
        ? {
          item_id: product?.uid || '',
          item_name: product?.name || '',
          price: product?.configurable_product_options_selection?.variant?.price_range?.minimum_price?.final_price?.value || '',
          category: getCategoryForGtm(product),
          categoryId: getCategoryForGtm(product, 'uid'),
          list_sku_variants: product?.list_sku_variants || [],
          item_brand: product?.marchio_attribute?.label || '',
          sku: product?.merchant_sku || '',
        }
        : {
          item_id: product?.uid || '',
          item_name: product?.name || '',
          price: product?.price_range?.minimum_price?.final_price.value || '',
          category: getCategoryForGtm(product),
          categoryId: getCategoryForGtm(product, 'uid'),
          item_brand: product?.marchio_attribute?.label || '',
          sku: product?.merchant_sku || '',
        };
    gtmPush({
      ecommerce: {
        actionField: {
          list: {},
        },
        detail: gtmProduct,
        PageType: 'product-page',
      },
      event: 'view_item',
    });
  };

  const pageViewPush = (pageType?: string) => {
    gtmPush({
      event: 'PageView',
      content_name: pageType || '',
      content_url: `${geminiBaseUrl}${fullPath}` || '',
    });
  };

  const addToWishlistPush = (wishlistItem) => {
    const gtmProduct =
      wishlistItem?.vsf_typename === 'ConfigurableProduct'
        ? {
          item_id: wishlistItem?.uid || '',
          item_name: wishlistItem?.name || '',
          price: wishlistItem?.configurable_product_options_selection?.variant?.price_range?.minimum_price?.final_price?.value || '',
          item_brand: wishlistItem?.marchio_attribute?.label || '',
          sku: wishlistItem?.merchant_sku || '',
        }
        : {
          item_id: wishlistItem?.uid || '',
          item_name: wishlistItem?.name || '',
          price: wishlistItem?.price_range?.minimum_price?.final_price.value || '',
          item_brand: wishlistItem?.marchio_attribute?.label || '',
          sku: wishlistItem?.merchant_sku || '',
        };
    gtmPush({
      ecommerce: {
        actionField: {
          list: {},
        },
        detail: gtmProduct,
      },
      event: 'add_to_wishlist',
    });
  }

  return {
    purchasePush,
    // checkoutPush,
    addToCartPush,
    getCategoryForGtm,
    removeFromCartPush,
    loginPush,
    checkoutStepPush,
    cartPush,
    categoryPush,
    productPush,
    pageViewPush,
    logoutPush,
    addToWishlistPush,
  };
};

export default useGtm;
